import axios from 'axios';

axios.defaults.withCredentials = true;

// DEVELOPMENT
// export const URL = process.env.REACT_APP_URL_DEV;
// export const PAYURL = process.env.REACT_APP_PAY_DEV;

// STAGING
// export const URL = process.env.REACT_APP_URL_STG;
// export const PAYURL = process.env.REACT_APP_PAY_STG;

// PRODUCTION
export const URL = process.env.REACT_APP_URL_PROD;
export const PAYURL = process.env.REACT_APP_PAY_PROD;

const config = {
  APIv4: {
    baseURL: URL + '/api/v4',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};

export const APIv4 = axios.create({ ...config.APIv4 });

[APIv4].forEach((api) => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      // Do something with response error
      if (
        error?.response?.status === 401 &&
        window.location.pathname !== '/login'
      ) {
      }
      return Promise.reject(error.response);
    }
  );
});

export const getCart = async ({ orderId }) =>
  await APIv4.get(`/getOrderDetails/${orderId}`);

export const updatePaymentTypeAPI = async ({ orderId, paymentType }) =>
  await APIv4.patch(`/updatePaymentMethod/${orderId}`, {
    payment_method: paymentType,
  });

export const prepareCheckoutAPI = async (body) =>
  await APIv4.post(`/checkout`, body);
