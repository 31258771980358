import { useEffect } from 'react';

const useScript = (url, id) => {
  useEffect(() => {
    let unmounted = false;

    const script = document.createElement('script');

    if (!unmounted) {
      script.src = url;
      script.async = true;
      script.id = id;
      script.defer = true;

      document.body.appendChild(script);
    }

    return () => {
      unmounted = true;
      // script.parentElement.removeChild(script);
    };
  }, [url]);
};

export default useScript;
