import React from 'react';

import useScript from '../hooks/useScript';
import { PAYURL } from '../api';

const HyperpayForm = ({ shopperResultUrl, checkoutId, paymentTypes }) => {
  useScript(`${PAYURL}?checkoutId=${checkoutId}`, 'hyperpay');

  return (
    <form
      paymentTarget
      action={shopperResultUrl}
      className="paymentWidgets"
      data-brands={paymentTypes}
    ></form>
  );
};

export default HyperpayForm;
