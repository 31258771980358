import React from 'react';
import { Collapse } from 'react-collapse';

import BGImage from '../assets/img/bg-image.svg';
import styles from '../assets/styles/main.module.css';
import Logo from '../assets/img/logo.svg';
import { getCart, prepareCheckoutAPI } from '../api';
import { ReactComponent as DownArrow } from '../assets/img/down-arrow.svg';
import { ReactComponent as BackArrow } from '../assets/img/back-arrow.svg';
import { ReactComponent as MADAICON } from '../assets/img/mada.svg';
import { ReactComponent as VISAICON } from '../assets/img/visa.svg';
import HyperpayForm from '../Components/HyperpayForm';
import { setGetParam } from '../utils';

const Checkout = ({
  orderId,
  shopperResultUrl,
  checkoutId,
  setCheckoutId,
  initialPaymentType = 'visa',
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(initialPaymentType);
  const [cartInfo, setCartInfo] = React.useState({
    data: [],
    success: false,
    error: null,
    loading: false,
  });

  React.useEffect(() => {
    const brand = document.getElementsByClassName('wpwl-label-brand')[0];
    if (brand) brand.innerHTML = 'Card Details';
  });

  React.useEffect(() => {
    const getCartInfo = async () => {
      try {
        setCartInfo({ data: [], error: null, success: false, loading: true });
        const {
          data: { data },
        } = await getCart({ orderId });
        setCartInfo({ data, error: null, success: true, loading: false });
      } catch (err) {
        setCartInfo({ data: [], error: err, success: false, loading: false });
      }
    };

    getCartInfo();
  }, []);

  const getVat = (price) => parseFloat(price) * 0.15;

  if (cartInfo.loading) return 'Loading...';

  const {
    data: { price, title, value },
  } = cartInfo;

  const total = parseFloat(price) + getVat(price);

  const prepareCheckout = async (paymentType) => {
    setPaymentType(paymentType);
    setCheckoutId(null);

    try {
      const script = document.querySelector('#hyperpay');
      script.parentElement.removeChild(script);
    } catch (err) {}

    try {
      const {
        data: { data },
      } = await prepareCheckoutAPI({ orderId, payment_method: paymentType });
      setGetParam('checkoutId', data.checkoutID);
      setCheckoutId(data.checkoutID);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ backgroundImage: `url(${BGImage}` }} className={styles.hero}>
      <div
        className={`${styles.back_button} flex justify-space-between`}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.history.back();
        }}
      >
        <BackArrow />
        <p style={{ color: 'white', marginLeft: 8, fontSize: 18 }}>
          Go back to website
        </p>
      </div>
      <div className={styles.center_box}>
        <div className={styles.box_header}>
          <h2>Bonat {paymentType}</h2>
          <p>Order Payment details</p>
          <img src={Logo} alt="Bonat" className={styles.box_header_img} />
        </div>
        <div className={styles.form_content}>
          <div className={styles.form_content_head}>
            <h4>Total</h4>
            <div className="flex justify-space-between main-color">
              {' '}
              <h4 style={{ fontWeight: 'bold' }}> {total} SAR</h4>{' '}
              <DownArrow
                className={isOpen ? 'up-arrow' : ''}
                style={{
                  cursor: 'pointer',
                  marginLeft: 8,
                }}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              />
            </div>
          </div>

          <Collapse isOpened={isOpen}>
            <div>
              <ul>
                <li className="flex justify-space-between">
                  <span className="normal-text">PRODUCT</span>{' '}
                  <span className="normal-text">VALUES</span>
                </li>
                <div className="hr" />
                <li className="flex justify-space-between">
                  <span>{title}</span> <span>{value} Units</span>
                </li>
                <div className="hr" />
                <li className="flex justify-space-between">
                  <span>Price</span> <span>{price} SAR</span>
                </li>
                <div className="hr" />

                <li className="flex justify-space-between">
                  <span>VAT</span> <span>{getVat(price).toFixed(2)} SAR</span>
                </li>
                <div className="hr" />

                <li className="flex justify-space-between bold">
                  <span className="normal-text">Total</span>
                  <span className="normal-text">{total} SAR</span>
                </li>
              </ul>
            </div>
          </Collapse>

          <div
            className="flex justify-space-between"
            style={{ gap: 12, marginTop: 12, paddingBottom: '1em' }}
          >
            <div
              className={`${styles.payment_box} ${
                paymentType === 'mada' ? 'active' : ''
              } `}
              onClick={async () => {
                prepareCheckout('mada');
              }}
            >
              <MADAICON />
            </div>
            <div
              className={`${styles.payment_box} ${
                paymentType === 'visa' ? 'active' : ''
              }`}
              onClick={() => {
                prepareCheckout('visa');
              }}
            >
              <VISAICON />
            </div>
          </div>

          {checkoutId && paymentType.toLowerCase() === 'mada' && (
            <div>
              <HyperpayForm
                shopperResultUrl={shopperResultUrl}
                checkoutId={checkoutId}
                paymentTypes="MADA"
              />
            </div>
          )}
          {checkoutId && paymentType.toLowerCase() === 'visa' && (
            <div>
              <HyperpayForm
                shopperResultUrl={shopperResultUrl}
                checkoutId={checkoutId}
                paymentTypes="VISA MASTER"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
