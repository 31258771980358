export const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    );

export function setGetParam(key, value) {
  if (window.history.pushState) {
    var params = new URLSearchParams(window.location.search);
    params.set(key, value);
    var newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      params.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
}
