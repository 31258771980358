import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Checkout from './pages/Checkout';
import { getQueryParams } from './utils';

function App() {
  const queries = getQueryParams();
  const orderId = queries?.orderId;
  const shopperResultUrl = queries?.shopperResultUrl;
  const [checkoutId, setCheckoutId] = React.useState(queries?.checkoutId);

  const paymentType = queries?.paymentType;

  return (
    <Router>
      <div>
        <Switch>
          <Route>
            <Checkout
              orderId={orderId}
              shopperResultUrl={shopperResultUrl}
              initialPaymentType={paymentType}
              checkoutId={checkoutId}
              setCheckoutId={setCheckoutId}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
